<template>
  <v-app id="inspire">
    <v-app-bar
      app
      color="#ffb80c"
    >
      <v-container class="py-0 fill-height">
        <v-avatar
          size="32"
        >
          <img src="@/assets/logo.png"/>
        </v-avatar>
        <v-btn
          v-for="link in links"
          :key="link"
          text
          @click="getNavigation()"
        >
          {{ link }}
        </v-btn>
        <v-spacer></v-spacer>
          <h6>powered by <a href="https://github.com/liampauling/betfair" target="_blank" rel="noreferrer noopener">betfairlightweight </a><v-icon x-small>mdi-launch</v-icon></h6>
      </v-container>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col>
            <v-sheet
              rounded="lg"
            >
              <v-alert
                dense
                type="error"
                v-if="this.connectionError"
              >{{this.connectionErrorMessage}}</v-alert>
              <v-col>
                <v-file-input
                  chips
                  show-size
                  prepend-icon="mdi-card-bulleted"
                  truncate-length="50"
                  label="Select historic data file"
                  :error="error"
                  :error-messages="errorMessage"
                  :loading="loading"
                  :disabled="disableButtons"
                  @change="onFilePicked"
                  hint=".bz2 or .gz file type (max file size 5MB)"
                  persistent-hint
                ></v-file-input>
              </v-col>
            </v-sheet>
          </v-col>
        </v-row>

        <v-row v-if="this.marketDefinition">
          <v-col cols="3">
            <v-sheet
              class="mx-auto"
              rounded="lg"
              min-height="60vh"
              max-height="60vh"
            >
              <v-card rounded="lg" flat>
                <v-card-subtitle>
                  Markets
                </v-card-subtitle>
              </v-card>
              <v-virtual-scroll
                :items="this.marketDefinitionValues"
                item-height="34"
                max-height="54vh"
                rounded="lg"
              >
                <template v-slot:default="{ item }">
                  <v-list-item dense link @click="market = item">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-sheet>
          </v-col>

          <v-col>
            <v-card flat rounded="lg" min-height="60vh" :loading="loadingDownload">
              <v-card-subtitle>
                {{ this.market.title }}
              </v-card-subtitle>
              <v-card-text>
                <h5>Date: {{ this.market.marketDate }}</h5>
                <h5>Event Id: {{ this.market.eventId }}</h5>
                <h5>Market Id: {{ this.market.marketId }}</h5>
              </v-card-text>
              <v-card-text>
                <v-col>
                  <v-data-table
                    :headers="headers"
                    :items="this.market.runners"
                    item-key="marketIdSelectionIdHandicap"
                    class="elevation-1"
                    :disable-pagination="true"
                    :hide-default-footer="true"
                  >
                  </v-data-table>
                </v-col>
              </v-card-text>

              <v-card-actions class="text-center">
                <v-row>
                  <v-col>
                    <v-btn
                      v-if="this.marketDefinition"
                      color="primary"
                      :loading="loadingDownload"
                      :disabled="disableButtons"
                      @click="downloadCSV()"
                    >
                      Download csv
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-dialog
                      v-model="dialog"
                      v-if="this.marketDefinition"
                      persistent
                      max-width="800px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          plain
                          v-bind="attrs"
                          v-on="on"
                          :disabled="disableButtons"
                        >
                          download settings
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Settings</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6">
                                <v-select
                                  v-model="metadata.dataPackage"
                                  :items="navigation.settings.dataPackages"
                                  label="Data Package"
                                  disabled
                                  hint="Auto detected"
                                  persistent-hint
                                >
                                  <template slot="selection" slot-scope="data">
                                    {{ title(data.item) }}
                                  </template>
                                </v-select>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-select
                                  v-model="metadata.fileType"
                                  :items="navigation.settings.fileTypes"
                                  label="File Type"
                                  disabled
                                  hint="Auto detected"
                                  persistent-hint
                                >
                                  <template slot="selection" slot-scope="data">
                                    {{ title(data.item) }}
                                  </template>
                                </v-select>
                              </v-col>
                              <v-col cols="12">
                                <v-combobox
                                  v-model="settings.marketColumns"
                                  :items="getMarketColumns()"
                                  label="Market Columns"
                                  color="secondary"
                                  multiple
                                  chips
                                  deletable-chips
                                  :clearable=true
                                ></v-combobox>
                              </v-col>
                              <v-col cols="12">
                                <v-combobox
                                  v-model="settings.selectionColumns"
                                  :items="getSelectionColumns()"
                                  label="Selection Columns"
                                  color="secondary"
                                  multiple
                                  chips
                                  deletable-chips
                                  :clearable=true
                                ></v-combobox>
                              </v-col>

                              <v-col cols="12" sm="6">
                                <v-switch
                                  v-model="settings.preplay"
                                  label="Preplay"
                                  color="secondary"
                                  hint="Output preplay data"
                                  persistent-hint="true"
                                  inset
                                ></v-switch>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-switch
                                  v-model="settings.inplay"
                                  label="Inplay"
                                  color="secondary"
                                  hint="Output inplay data"
                                  persistent-hint="true"
                                  inset
                                ></v-switch>
                              </v-col>

                              <v-col>
                                <v-autocomplete
                                  :items="navigation.settings.conflation"
                                  label="Conflation"
                                  required
                                  v-model="settings.conflation"
                                  hint="Update every x seconds"
                                  persistent-hint
                                >
                                  <template slot="selection" slot-scope="data">
                                    {{ data.item }}s
                                  </template>
                                  <template slot="item" slot-scope="data">
                                    {{ data.item }}s
                                  </template>
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                          >
                            save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-card-actions>
             </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer app height="30vh" color="rgb(117, 117, 117, 0.8)">
    </v-footer>
  </v-app>
</template>

<script>
  import {HTTP_API} from './http-common';

  export default {
    data: () => ({
      disableButtons: true,
      connectionError: false,
      connectionErrorMessage: '',
      error: false,
      errorMessage: null,
      loading: false,
      loadingDownload: false,
      dialog: false,
      navigation: {},
      headers: [
          {
            text: 'Selection Id',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Selection Name', value: 'name' },
          { text: 'BSP', value: 'bsp' },
          { text: 'Status', value: 'status' }
        ],
      settings: {"conflation": 10, "preplay": true, "inplay": true, "marketColumns": ["marketId", "publishTime"], "selectionColumns": ["selectionId", "lastPriceTraded"]},
      file: null,
      metadata: null,
      marketDefinition: null,
      marketDefinitionValues: [],
      market: {},
      selectedMarket: 1,
      links: [
        'Betfair Historic Data Processor'
      ],
    }),
    mounted: function() {
      this.getNavigation();
    },
    methods: {
      scrollToTop() {
        window.scrollTo(0,0);
      },

      getMarketColumns: function() {
        return this.navigation.settings.columns.mcm.market[this.metadata.dataPackage];
      },

      getSelectionColumns: function() {
        return this.navigation.settings.columns.mcm.selection[this.metadata.dataPackage];
      },

      title(str) {
        return str.replace(
          /\w\S*/g,
          function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
        );
      },

      getFileNameFromHeaders(headers) {
        const contentDisposition = headers['content-disposition'].split('; ');
        const fileNameHeader = contentDisposition.find(x => x.startsWith('filename='));
        let fileName = fileNameHeader.split('=')[1];
        fileName = fileName.substr(1, fileName.length -2);
        return fileName
      },

      createErrorMessage(err, msg) {
        if (err.response) {
          if (err.response.status === 500) {
            return msg + ': Server error'
          } else {
            if ('message' in err.response.data) {
              return msg + ': ' + err.response.data.message;
            } else if ('detail' in err.response.data) {
              return msg + ': ' + err.response.data.detail;
            } else {
              return msg;
            }
          }
        } else {
          return msg + ': Network error';
        }
      },

      getNavigation: function() {
        this.disableButtons = true;
        HTTP_API.get('navigation/')
        .then((response) => {
          this.navigation = response.data;
          this.disableButtons = false;
          this.connectionError = false;
        })
        .catch((err) => {
          this.connectionError = true;
          this.connectionErrorMessage = this.createErrorMessage(err, 'API Error');
          console.log(err);
        });
      },

      onFilePicked: function(file) {
        // set loader / reset errors
        this.loading = true;
        this.errorMessage = null;
        this.error = false;
        this.scrollToTop();
        // reset defaults
        this.metadata = null;
        this.marketDefinition = null;
        this.settings = {"conflation": 10, "preplay": true, "inplay": true, "marketColumns": ["marketId", "publishTime"], "selectionColumns": ["selectionId", "lastPriceTraded"]};
        // process file
        if ((file !== null) & (file !== undefined)) {
          // validate file extension
          // if (file.type !== 'application/x-bzip2' && file.type !== 'application/x-gzip') {
          //   this.errorMessage = 'Incorrect file type selected';
          //   this.error = true;
          //   this.loading = false;
          //   return
          // }
          // validate file size
          if (file.size > 5 * 1024 * 1024) {
            this.errorMessage = 'File size too large for processing (5MB max)';
            this.error = true;
            this.loading = false;
            return
          }
          // upload file to get marketDefinition
          var formData = new FormData();
          formData.append("file", file);
          HTTP_API.post(
            'file-process/market-definition/',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          )
          .then((response) => {
            this.metadata = response.data.metadata;
            this.marketDefinition = response.data.marketDefinitions;
            var values = Object.keys(response.data.marketDefinitions).map(function(key){
                return response.data.marketDefinitions[key];
            });
            this.marketDefinitionValues = values;
            this.market = values[0];
            this.file = file;
          })
          .catch((err) => {
            this.errorMessage = this.createErrorMessage(err, 'Error in processing file');
            this.error = true;
          })
          .then(() => {
            this.loading = false;
          });
        } else {
          this.loading = false;
        }
      },

      downloadCSV: function() {
        this.errorMessage = null;
        this.error = false;
        this.loadingDownload = true;
        var formData = new FormData();
        formData.append("file", this.file);
        formData.append('marketId', this.market.marketId);
        formData.append('dataPackage', this.metadata.dataPackage);
        formData.append("inplay", this.settings.inplay);
        formData.append("preplay", this.settings.preplay);
        formData.append("conflation", this.settings.conflation);
        formData.append("marketColumns", this.settings.marketColumns);
        formData.append("selectionColumns", this.settings.selectionColumns);
        HTTP_API.post(
            'file-process/csv/',
            formData,
            {
              headers: {'Content-Type': 'multipart/form-data'},
              responseType: 'blob'
            }
          )
          .then((response) => {
            let blob = new Blob([response.data], { type: 'blob' });
            let link = document.createElement('a');
            let fileName = this.getFileNameFromHeaders(response.headers);
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
          })
          .catch((err) => {
            this.errorMessage = this.createErrorMessage(err, 'Error in processing file');
            this.error = true;
          })
          .then(() => {
            this.loadingDownload = false;
          });
      }
    }
  }
</script>
